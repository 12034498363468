import React from 'react';
import '../styles/App.css'; 
import { Link } from 'react-router-dom';

const Footer = () => {
  return (

    <footer className="footer ">
      <div className="container">
        <div className="d-flex justify-content-center">
        <Link to="/privacy-policy" className="mx-3 ftxt">POLITIKA PRIVATNOSTI</Link>
        <Link to="/cookie-policy" className="mx-3 ftxt">KOLAČIĆI</Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
