import React from 'react';
import '../styles/main.css'; // Importing CSS for styling
import slika1 from '../img/slika2.jpg';
import Fade from 'react-reveal/Fade';



const Slikadru = ({ image, text }) => {
  return (
    <Fade left> 
    <div className=" row m-md-5 g-5 p-0 image-text-container">
      
      <div  className="col-md-6 col-sm-12 p-4 text-container">
        <h1 className="col-12 text-s"> Mi ćemo vam pružiti sjajne usluge</h1>
        <p className='col-12 para'>- relaksaciju i užitak</p>
        <p className='col-12 para'>- masažu sertifikovanog osoblja</p>
      </div>

      <div className="col-md-6 col-sm-12 p-0 ">
        <img src={slika1} alt="Slika" className="col-12 image" />
      </div>
    </div>
   </Fade> 
  );
}

export default Slikadru;
