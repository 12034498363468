import React, { useRef, useState } from 'react';
import '../styles/main.css'; // Importing CSS for styling
import Fade from 'react-reveal/Fade'; // Uvoz React Reveal Fade komponente
import validateForm from "../components/validation"; // Uvoz funkcije za validaciju

const ContactForm = () => {
  const formRef = useRef();
  const [formData, setFormData] = useState({
    message: "",
    to: "nikola87m@yahoo.com"
  });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [submitError] = useState(false); // Stanje za prikaz poruke o grešci



  const sendContactMessage = (event) => {
    if (!validateForm(formRef.current)) return;
    const _formData = new FormData();
    for (var i in formData) {
      _formData.append(i, formData[i]);
    }
    fetch('https://contactform.orla.rs/mail_cv.php', { method: 'POST', body: _formData }).then(res => res.text());
    setFormSubmitted(true);
  };

  const changeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Prikaz komponente
  return (
    <Fade className="fadeInLeft" delay={300}>
      <div className='back'>
      <div className="contact-form-bg">
        <div className="section-title mb-10 text-center kontakt">
          <h2>Kontaktirajte nas</h2>
          <h3>+381 64 66 29 346</h3>
          <h4>nikola87m@yahoo.com</h4>
        </div>
        {!formSubmitted && !submitError && ( // Prikaži formu ako nije poslata i ako nema greške
          <form
            onSubmit={sendContactMessage}
            className="m-4 form-style-one"
            action="#"
            name="contact-form"
            ref={formRef}
          >
            <div className="form-group">
            <label htmlFor="emailid" className="form-label">Ime</label>
              <input
                type="text"
                id="name"
                name="name"
                className="form-control"
                placeholder=""
                required
                onChange={changeHandler}
              />
            </div>
            <div className="form-group">
            <label htmlFor="emailid" className="form-label">Telefon</label>
              <input
                type="text"
                id="phone"
                name="phoneNumber"
                className="form-control"
                placeholder=""
                required
                onChange={changeHandler}
              />
            </div>
            <div className="col-12">
            <label htmlFor="emailid" className="form-label">Email</label>
                  <div className="form-group">
                    <input
                      type="email"
                      id="emailid"
                      name="email"
                      className="form-control"
                      defaultValue=""
                      placeholder=""
                      required
                      onChange={changeHandler}
                    />
                  </div>
                </div>
            <div className="form-group">
            <label htmlFor="emailid" className="form-label">Poruka</label>
              <textarea
                name="message"
                id="message"
                className="form-control"
                rows={3}
                placeholder=""
                required
                onChange={changeHandler}
              />
            </div>
            <div className="text-center text-md-start">
              <button type="submit" className="theme-btn">Pošalji</button>
            </div>
          </form>
        )}
        {formSubmitted && ( // Prikazi poruku nakon uspešnog slanja
          <h5 className="w-100 my-5 py-4 rounded bg-message text-white text-center">Hvala vam što ste nas kontaktirali!</h5>
        )}
        {submitError && ( // Prikazi poruku o grešci ako se desi greška pri slanju
          <h5 className="w-100 my-5 py-4 rounded bg-message text-white text-center">Došlo je do greške prilikom slanja poruke. Molimo vas, pokušajte ponovo kasnije.</h5>
        )}
      </div>
      </div>
    </Fade>
  )
}

export default ContactForm