import React from 'react';
import '../styles/main.css'; // Importing CSS for styling
import image1 from '../img/wellness-7399690.jpeg';
import Fade from 'react-reveal/Fade';

const Centralslika1 = ({ image, text }) => {
  return (
  <Fade top>
    <div className="row centered-image-container">
      <div className="col-12 text-container">
        <p className="col-12 p-1 text">Mašaža je omiljeni čin hedonizma i zdravlja</p>
      </div>

      <div className="col-12 ">
        <img src={image1} alt="Slika" className="col-md-7 col-sm-12 image image" />
      </div>
      <div className="col-12 text-container">
        <p className="col-12 p-md-0 p-3 text1">POZIVAMO VAS DA NAS POSETITE I OSETITE SNAGU OPUŠTANJA</p>
      </div>

    </div>
  </Fade>    
  );
}

export default Centralslika1;
