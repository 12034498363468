import React, { useState } from 'react';
import '../styles/main.css'; // Importing CSS for styling

// Uvezite slike direktno
import photo1 from '../img/background.jpg';
import photo2 from '../img/slika1.jpg';
import photo3 from '../img/slika2.jpg';
import photo4 from '../img/massage.jpg';
import photo6 from '../img/1.jpeg';
import photo7 from '../img/2.jpeg';
import photo8 from '../img/15.jpeg';
import photo9 from '../img/16.jpeg';
import photo10 from '../img/18.jpeg';
import photo11 from '../img/19.jpeg';
import photo12 from '../img/20.jpeg';



const PhotoGallery = () => {
  const allPhotos = [
    { src: photo1, width: 3, height: 4, alt: 'Forest path' },
    { src: photo2, width: 3, height: 4, alt: 'Forest path' },
    { src: photo3, width: 3, height: 4, alt: 'Forest path' },
    { src: photo4, width: 3, height: 4, alt: 'Forest path' },
    { src: photo6, width: 3, height: 4, alt: 'Forest path' },
    { src: photo7, width: 3, height: 4, alt: 'Forest path' },
    { src: photo8, width: 3, height: 4, alt: 'Forest path' },
    { src: photo9, width: 3, height: 4, alt: 'Forest path' },
    { src: photo10, width: 3, height: 4, alt: 'Forest path' },
    { src: photo11, width: 3, height: 4, alt: 'Forest path' },
    { src: photo12, width: 3, height: 4, alt: 'Forest path' },

  ];

  const [visiblePhotos, setVisiblePhotos] = useState(4);

  const showMorePhotos = () => {
    setVisiblePhotos((prevVisiblePhotos) => prevVisiblePhotos + 4);
  };

  return (
    <div>
      <div className="photo-gallery">
        {allPhotos.slice(0, visiblePhotos).map((photo, index) => (
          <img key={index} src={photo.src} alt={photo.alt} className="photo" />
        ))}
      </div>
      {visiblePhotos < allPhotos.length && (
        <div className="button-container">
        <button onClick={showMorePhotos}>Prikaži više</button>
      </div>
      )}
    </div>
  );
};

export default PhotoGallery;
