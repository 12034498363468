import React from 'react';
import '../styles/main.css'; // Importing CSS for styling
import image1 from '../img/massage.jpg';
import Fade from 'react-reveal/Fade';

const Centralslika = ({ image, text }) => {
  return (
  <Fade top>
    <div className=" centered-image-container">
      <div className="col-12 ">
        <img src={image1} alt="Slika" className=" col-md-8 col-sm-12 image" />
      </div>
      <div className="col-12 text-container">
        <p className="col-12 p-md-0 p-1  text">Lepotu i zdravlje tela prati <br />mentalna udobnost</p>
      </div>
    </div>
  </Fade>    
  );
}

export default Centralslika;
