import React, { useEffect } from 'react';
import './styles/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Home from './pages/Home';
import PrivacyPolicy from './pages/privacy-policy'
import CookiePolicy from './pages/cookie-policy';
import Footer from './components/Footer';
import BackToTopButton from './components/BackToTopButton';

function App() {
  useEffect(() => {
    document.title = "Masaza Arandjelovac";
  }, []);


  return (
    <>
      <Header />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/cookie-policy" element={<CookiePolicy />} />
      </Routes>
    <Footer />
    <BackToTopButton />
    </>
  );
}

export default App;
