import React from 'react';
import '../styles/main.css'; // Importing CSS for styling
import slika1 from '../img/slika1.jpg';
import Fade from 'react-reveal/Fade';

const Slikaprv = ({ image, text }) => {
  return (
  <Fade right > 
    <div  className='row m-md-5'>
    <div className="col-12 row g-0 p-0 image-text-container">
      <div className="col-md-6 col-12 p-0 ">
        <img src={slika1} alt="Slika" className="col-12 image" />
      </div>
      <div className="col-md-6 col-12 g-md-0 g-5 p-4 text-container" >
        <h1 className="col-12 m-md-5 m-0 text">Naša briga je nega i odrzavanje vašeg tela.<br />Naše najvažnije prednosti su odgovornost, posvećenost poslu, predanost,<br /> tačnost i efikasnost u uslugama koje nudimo svojim klijentima. </h1>
      </div>
    </div>
    </div>
    </Fade>   
  );
}

export default Slikaprv;
