import React, { useEffect } from 'react';
import '../styles/App.css';
import MassageInfo from '../components/MassageInfo';
import NaseUsluge from '../components/NaseUsluge';
import Slikaprv from '../components/Slikaprv';
import Slikadru from '../components/Slikadru';
import Centralslika from '../components/Centralslika';
import Centralslika1 from '../components/Centralslika1';
import PhotoGallery from '../components/PhotoGallery';
import 'bootstrap/dist/css/bootstrap.min.css';
// import { BrowserRouter as Router } from 'react-router-dom';
import ContactForm from '../components/ContactForm';


function Home() {
  useEffect(() => {
    document.title = "Masaza Arandjelovac";
  }, []);


  return (
   <>
     
     <div className='App'>
        <div className="content">
          <main>
            <section id="Home">
              <MassageInfo />
            </section>

            <div className="marg">
              <section id="Services">
                <NaseUsluge />
              </section>
              <Slikaprv />
              <Slikadru />
              <Centralslika />
            </div>
            <section id="Gallery">
              <PhotoGallery />
            </section>
            <Centralslika1 />
            <section className='m-0' id="Contact">
              <ContactForm />
            </section>
          </main>
        </div>
        

        </div>
        </> 
    
  );
}

export default Home;
