import React from 'react';
import '../styles/main.css'; // Importing CSS for styling
import background from '../img/background.jpg';  // Importing the background image
import Fade from 'react-reveal/Fade';

const MassageInfo = () => {
  return (
    <div>
      <div className="g-0 massage-info" style={{ backgroundImage: `url(${background})` }}>
        <Fade top>
          <h1><b>H & C BODY MASSAGE</b></h1>
          <h2><b>Pružamo usluge opuštanja i rehabilitacije tela kroz <br /> razne tipove masaža.</b></h2>
        </Fade>
      </div>

      <div className="mobile-massage-info">
        <Fade top>
          <div className="image-container1" style={{ backgroundImage: `url(${background})` }}></div>
          <div className="text-container1">
            <h1><b>H & C BODY MASSAGE</b></h1>
            <h2><b>Pružamo usluge opuštanja i rehabilitacije tela kroz razne tipove masaža.</b></h2>
          </div>
        </Fade>
      </div>
    </div>

  );
}

export default MassageInfo;
