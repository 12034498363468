import { HashLink as NavLink } from 'react-router-hash-link'; // Importujemo HashLink kao NavLink
import logo from '../img/logo.png';
import '../styles/App.css';
import React, { useState } from 'react';
import { Fade } from 'react-reveal';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="App">
      <header className="row m-0 g-0 header">
        <div className=" container">
          <div className="row col-12 m-0 align-items-center ">
            <div className="col-6 col-md-3 logo">
              <NavLink smooth to="/#Home"><img src={logo} alt="Centar za Masažu" className="img-fluid" /></NavLink>
            </div>
            <div className="col-md-9 d-none d-md-block text-right">
              <nav navbar-expand-lg navbar-light>
                <ul className="list-unstyled d-flex justify-content-end ulgl">
                  <li><NavLink smooth to="/#Home">Početna</NavLink></li>
                  <li><NavLink smooth to="#Services">Usluge</NavLink></li>
                  <li><NavLink smooth to="#Gallery">Galerija</NavLink></li>
                  <li><NavLink smooth to="#Contact">Kontakt</NavLink></li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </header>

      {/* Mobile Header */}
      <header className="header mobile-header">
        <div className="container">
          <div className="accordion-button ">
            <div className="col-6  d-flex ">
              <div className='col-6'>
                <NavLink smooth to="/#Home"><img src={logo} alt="Centar za Masažu" className="img-fluid" /></NavLink>
              </div>
            </div>
            <div className="col-3 col-6 d-flex justify-content-end mt-3">
              <button className="menu-btn1" onClick={toggleSidebar}>
                &#9776;
              </button>
            </div>
          </div>
        </div>
      </header>

      <div className={`sidebar ${isOpen ? 'open' : ''}`}>
        <div>
          <Fade top delay={200}>
            <ul className="list-unstyled mb-0">
              <li className="d-flex justify-content-between align-items-center pb-0">
                <NavLink smooth to="/#Home" onClick={toggleSidebar}>Početna</NavLink>
                <button className="close-btn pb-0" onClick={toggleSidebar}>&times;</button>
              </li>
              <li><NavLink smooth to="#Services" onClick={toggleSidebar}>Usluge</NavLink></li>
              <li><NavLink smooth to="#Gallery" onClick={toggleSidebar}>Galerija</NavLink></li>
              <li><NavLink smooth to="#Contact" onClick={toggleSidebar}>Kontakt</NavLink></li>
            </ul>
          </Fade>
        </div>
      </div>
    </div>
  );
}

export default Header;
