import React from 'react';
import '../styles/main.css'; // Importing CSS for styling
import logo from '../img/logo.png';  // Importing the logo
import Usluge from "../data/usluge.json";
import Fade from 'react-reveal/Fade';


const NaseUsluge = () => {
  return (
    <div className='row g-0 m-md-5 m-3'>
      <div className="row  g-0 p-0 rectangle-container">
    <Fade top>
        <h1><b>NAŠE USLUGE</b></h1>
    </Fade>


        {Usluge.map((item) => (
        <Fade left delay={item.delay} >
          <div className="col-12 col-md-6 col-lg-4 mb-5 g-3">
            <div className='col-12  rectangle  h-100'  >
              <img src={logo} alt="Logo 1" />
              <h3 className="rectangle-title"><b>{item.title}</b></h3>
              <p className="uppercase">{item.text}</p>
            </div>
          </div>
        </Fade>  
        ))}
      </div>
    </div>


  );
}

export default NaseUsluge;
